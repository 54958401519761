<template>
  <div>
    <base-section id="serviceproviderfaq-account" space="56">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="6">
            <base-info-card :title="$t('faq.title')" />
          </v-col>
        </v-row>
      </v-container>
      <!-- THEME: Enregistrement au système -->
      <v-container>
        <v-card>
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title>Enregistrement au système</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab> Mot de passe </v-tab>
            <v-tab> Enregistrement-Erreur </v-tab>
            <!--
            <v-tab>
              Sujet 3
            </v-tab>
            -->
            <!------------------------ SOUS-DETAIL Mot de passe    ------------------------------------------------>
            <v-tab-item>
              <v-card flat>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Quelles sont les contraintes sur le mot de passe?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Votre mot de passe doit: - contenir 10 caractères minimum
                      - contenir au moins 1 chiffre - contenir au moins 1
                      caractère spécial (^ $ * . [ ] { } ( ) ? - " ! @ # % &amp;
                      / \ , > &lt; ' : ; | _ ~ ` + =) - contenir au moins 1
                      lettre majuscule - contenir au moins 1 lettre minuscule
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Question 2
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Réponse 2.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  -->
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
            <!------------------------ SOUS-DETAIL Enregistrement-Erreur    ------------------------------------------------>
            <v-tab-item>
              <v-card flat>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Je ne comprends pas l'erreur lors de l'enregistrement!
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      N'hésitez pas à remonter en haut de l'écran pour voir la
                      cause de l'erreur (mot de passe non conforme, nom
                      d'utilisateur déjà existant).
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Je recois un mail après mon enregistrement
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Afin de finaliser l'enregistrement, vous devez entrer un
                      code transmis par mail.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
            <!--
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            -->
          </v-tabs>
        </v-card>
      </v-container>
      <!-- THEME: Amélioration de mes ventes -->
      <v-container>
        <v-card>
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title>Amélioration de mes ventes</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab> QR code </v-tab>
            <v-tab> Email de promotion </v-tab>
            <!--
            <v-tab>
              Enregistrement-Erreur
            </v-tab>
            <v-tab>
              Sujet 3
            </v-tab>
            -->
            <!------------------------ SOUS-DETAIL QR code    ------------------------------------------------>
            <v-tab-item>
              <v-card flat>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Trouver vos services plus rapidement
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Afin de simplifier l'accès à vos service, nous vous
                      invitons à ajouter un QR code sur votre carte de visite.
                      Cela permettra à vos utilisateurs de vous trouver plus
                      facilement. L'image du QR code peut-être téléchargée
                      <a :href="qrCodeUrl" target="_blank"> ici </a>.
                      <!--
                      <base-img
                        :src="require('@/assets/qr-kidiwiservices-transparent.png')"
                        alt="KIDIWI services QR code"
                        contain
                        max-with="256"
                        max-heigth="140"
                        width="100%"
                      />
                      -->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!------------------------ SOUS-DETAIL Email de promotion    ------------------------------------------------>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Envoyer un mail à votre clientèle pour booster votre
                      activité
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Objet: Bienvenue sur [Nom du fournisseur de services]<br />
                      Cher client,<br />
                      Nous sommes ravis de vous accueillir sur [Nom du
                      fournisseur de services]. Nous sommes fiers de vous
                      présenter notre catalogue complet de services, récemment
                      publié sur KidiwiServices.com.<br />
                      Grâce à notre plateforme conviviale, vous pouvez
                      facilement explorer l'ensemble de nos services et
                      soumettre une demande en quelques clics. Nous sommes là
                      pour vous aider à réaliser vos projets de manière efficace
                      et sans stress.<br />
                      Lorsque vous soumettez une demande de service, nous
                      recevons instantanément une notification pour nous
                      permettre de répondre rapidement à votre demande. Nous
                      sommes déterminés à offrir un service clientèle de
                      première classe et à satisfaire toutes vos demandes de
                      services.<br />
                      N'hésitez pas à nous contacter si vous avez des questions
                      ou des besoins supplémentaires. Nous sommes là pour vous
                      aider à chaque étape de votre projet.<br />
                      Meilleures salutations,<br />
                      Le [Nom du fournisseur de services]<br />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component({ name: "AnonymousFaqAccount" })
class AnonymousFaqAccount extends Vue {
  qrCodeUrl = `${window.location.origin}/qr-kidiwiservices-transparent.png`;
}

export default AnonymousFaqAccount;
</script>
